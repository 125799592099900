import Alpine from "./alpine.js";
import focus from "./alpine-focus.js";
import intersect from "./alpine-intersect.js";

Alpine.plugin(focus);
Alpine.plugin(intersect);

export function isFormElem(ev) {
  if (!ev || !ev.target) return false;
  const tag = ev.target.tagName.toLowerCase();
  return tag == "input" || tag == "textarea";
}

export const apiRoot = (function () {
  const elem = document.querySelector('meta[name="api"]');
  if (!elem) throw new Error("meta[name='api'] tag not found");
  if (!elem.hasAttribute("content"))
    throw new Error('meta[name="api"] tag has no content attribute');
  return elem.getAttribute("content");
})();

console.debug(`[ytpodd] apiRoot=${apiRoot}`);

export function relTime(s) {
  // server returns UTC datetimes, so convert to local time
  const t = new Date(s);
  t.setMinutes(t.getMinutes() - new Date().getTimezoneOffset());
  const ms = Math.abs(new Date() - t);
  const secs = ms / 1000;

  if (ms < 1000) {
    return "<1s";
  }

  const units = ["y", "d", "h", "m", "s"],
    divisors = [31536000, 86400, 3600, 60, 1],
    digits = [1, 0, 1, 0, 0];

  for (let i = 0; i < units.length; i++) {
    const unit = units[i],
      div = divisors[i],
      dg = digits[i];
    if (secs > div) {
      if (dg == 0) {
        return Math.trunc(secs / div) + unit;
      }

      const n = Math.pow(10, dg + 1);
      return Math.trunc(((secs / div) * n) / n) + unit;
    }
  }
  return "";
}

Alpine.data("app", () => ({
  appName: "ytpodd",
  apiRoot: apiRoot,
  info: {},
  version: null,
  debug: false,
  apiUrl: null,
  initialised: false,
  error: null,

  openPanel: null,

  init() {
    const url = `${apiRoot}/info`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((info) => {
        const apiUrl = new URL(window.location.href);
        apiUrl.pathname = apiRoot;
        apiUrl.search = "";
        this.apiUrl = apiUrl.toString();

        console.debug(info);
        this.appName = info.appName;
        this.version = info.version;
        this.debug = info.debug;
        this.info = info;
        this.initialised = true;
      })
      .catch((error) => {
        this.error = error;
      });
  },

  openInfo(ev) {
    if (isFormElem(ev)) return;
    const dialog = document.querySelector("#apiInfo");
    dialog.addEventListener("close", (ev) => {
      console.debug(`[ytpodd] apiInfo closed`, ev);
    });
    dialog.showModal();
    console.debug(`[ytpodd] apiInfo: returnValue =`, dialog.returnValue);
  },

  openAdmin(ev) {
    if (isFormElem(ev)) return;
    // Alpine.dispatch("open-admin");
    this.openPanel = this.openPanel == "admin" ? null : "admin";
  },

  openTools(ev) {
    if (isFormElem(ev)) return;
    // Alpine.dispatch("open-tools");
    this.openPanel = this.openPanel == "tools" ? null : "tools";
  },
}));

Alpine.data("admin", () => ({
  apiRoot: apiRoot,
  error: null,
  playlists: [],
  initialised: false,
  actionInProgress: false,
  uploadQueue: [],

  open() {},

  loadSettings() {
    const url = `${apiRoot}/admin/config`;
    this.playlists.forEach((pl) => {
      pl.working = true;
    });
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((playlists) => {
        this.playlists = playlists;
        this.initialised = true;
      })
      .catch((error) => {
        this.error = error;
      });
  },

  performAction(action) {
    switch (action) {
      case "update-feeds":
        action = "update";
        break;
      case "clean-cache":
        action = "clean";
        break;
      default:
        console.error(`unknown action: ${action}`);
        return;
    }

    const req = new Request(`${apiRoot}/admin/action/${action}`, {
      method: "POST",
    });
    this.actionInProgress = true;
    fetch(req)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      })
      .catch((error) => (this.error = error))
      .finally(() => (this.actionInProgress = false));
  },

  refreshFeed(pl) {
    console.debug(`refreshing ${pl.title} ...`);
    const req = new Request(`${apiRoot}/admin/refresh/${pl.ytid}`, {
      method: "POST",
    });
    this.configRequest(pl, req);
  },

  deleteFeed(pl) {
    console.debug(`deleting feed ${pl.title} ...`);
    const req = new Request(`${apiRoot}/admin/feed/${pl.ytid}`, {
      method: "DELETE",
    });
    pl.working = true;
    fetch(req)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        for (let i = 0; i < this.playlists.length; i++) {
          if (this.playlists[i].ytid === pl.ytid) {
            this.playlists.splice(i, 1);
            return;
          }
        }
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        pl.working = false;
      });
  },

  deleteCustomArtwork(pl) {
    console.debug(`deleting custom artwork for ${pl.title} ...`);
    const req = new Request(`${apiRoot}/admin/artwork/${pl.ytid}`, {
      method: "DELETE",
    });
    this.configRequest(pl, req);
  },

  setCustomMaxItems(pl, maxItems) {
    console.debug(
      `custom maxItems for ${pl.title}: ${pl.maxItems} --> ${maxItems}`,
    );

    if (maxItems == pl.maxItems) {
      return;
    }

    let req;
    if (maxItems === null) {
      req = new Request(`${apiRoot}/admin/config/${pl.ytid}`, {
        method: "DELETE",
      });
    } else {
      req = new Request(`${apiRoot}/admin/config`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          maxItems: maxItems,
          playlistId: pl.ytid,
        }),
      });
    }
    this.configRequest(pl, req);
  },

  purgeCache(pl) {
    console.debug(`deleting cached files for ${pl.title} ...`);
    pl.working = true;
    const req = new Request(`${apiRoot}/admin/cache/${pl.ytid}`, {
      method: "DELETE",
    });
    this.configRequest(pl, req);
  },

  uploadArtwork(pl, file) {
    console.debug(`uploading new cover for ${pl.title}:`, file);
    const data = new FormData();
    data.append("artwork", file);
    const req = new Request(`${apiRoot}/admin/artwork/${pl.ytid}`, {
      method: "POST",
      body: data,
    });
    pl.customArtwork = "./a/favicon.svg";
    this.configRequest(pl, req);
    pl.customArtwork = pl.customArtwork + "?v=1";
  },

  bulkUploadArtwork(files) {
    this.uploadQueue = [];
    const playlists = {};
    this.playlists.forEach((pl) => {
      playlists[pl.ytid] = pl;
    });

    Array.from(files).forEach((file) => {
      let ytid;
      let status = "pending";
      const m = file.name.match(/^([A-Za-z0-9_-]+)\.jpe?g$/);
      if (m) ytid = m[1];

      const pl = playlists[ytid];
      if (!pl) {
        status = "unknown";
        console.debug(`artwork for unknown feed: ${ytid}`);
      }

      console.debug(`queuing ${file.name} for feed ${ytid}`);
      self.uploadQueue.push({
        name: file.name,
        ytid: ytid,
        pl: pl,
        status: status,
        file: file,
        error: null,
      });
    });

    Alpine.nextTick(this.startUpload.bind(this));
  },

  startUpload() {
    if (!this.uploadQueue.length) return;

    const prom = new Promise(() => {
      for (let i = 0; i < this.uploadQueue.length; i++) {
        const upload = this.uploadQueue[i];
        if (upload.status !== "pending") continue;

        const data = new FormData();
        data.append("artwork", upload.file);
        const req = new Request(`${apiRoot}/admin/artwork/${upload.ytid}`, {
          method: "POST",
          body: data,
        });
        console.debug(`uploading ${upload.name} ...`);
        upload.status = "running";
        upload.pl.working = true;
        fetch(req)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((pl) => {
            this.updatedPlaylist(pl);
            upload.status = "success";
          })
          .catch((error) => {
            this.error = error;
            upload.status = "failed";
            upload.error = error;
          })
          .finally(() => (upload.pl.working = false));
      }
    });

    Promise.resolve(prom);
  },

  configRequest(pl, req) {
    pl.working = true;
    fetch(req)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((pl) => {
        this.updatedPlaylist(pl);
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        pl.working = false;
      });
  },

  updatedPlaylist(pl) {
    console.debug(`playlist from server:`, pl);
    for (let i = 0; i < this.playlists.length; i++) {
      const cur = this.playlists[i];
      if (cur.ytid === pl.ytid) {
        console.debug(`replacing existing playlist at ${i}`);
        this.playlists[i] = pl;
        return;
      }
    }
  },
}));

Alpine.data("search", () => ({
  apiRoot: apiRoot,
  error: null,
  query: null,
  where: null,
  results: [],
  working: false, // shows spinner

  init() {
    const params = new URLSearchParams(document.location.search);
    console.debug(params);
    this.query = params.get("q") || "";
    this.where = params.get("where") || "channels";
    if (this.query && this.where) {
      this.search();
    } else {
      document.querySelector("#query").focus();
    }
  },

  focusSearch(ev) {
    if (isFormElem(ev)) return;
    const input = document.querySelector("#query");
    Alpine.nextTick(() => {
      input.focus();
      input.select();
    });
    // setTimeout(() => {
    //   input.focus();
    //   input.select();
    // }, 100);
  },

  search() {
    const query = this.query.trim();
    if (!query) return;
    document.querySelector("#query").blur();
    const pageUrl = new URL(window.location.href),
      // apiUrl = `/search/${encodeURIComponent(this.where)}/${encodeURIComponent(query)}`;
      apiUrl = `${apiRoot}/search/${this.where}?q=${encodeURIComponent(query)}`;

    pageUrl.search = `?q=${encodeURIComponent(query)}&where=${this.where}`;
    console.debug(`searching ${this.where} for "${query}" ...`);
    // console.debug(`apiUrl=${apiUrl}, pageUrl=${pageUrl}`);

    this.results = [];
    this.error = null;
    history.pushState({}, "ytpodd", pageUrl);
    this.working = true;
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((results) => {
        // console.debug(results);
        console.log(`${results.length} result(s) for "${query}"`);
        this.results = results;
      })
      .catch((error) => {
        this.error = error.toString();
      })
      .finally(() => {
        this.working = false;
      });
  },
}));

// Alpine.start();
// window.Alpine = Alpine;

export default function run() {
  Alpine.start();
}
